<template>
	<el-dialog :title="!dataForm.recNo ? '新增跟踪记录' : '修改跟踪记录'" :close-on-click-modal="false" :visible.sync="visible"
		width="950px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="登记时间" prop="registerTime">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.registerTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="跟踪内容" prop="problemContent">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.problemContent" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="备注" prop="problemRemark">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.problemRemark" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "problem-record-add-or-update",
		data() {

			return {
				visible: false,
				inline: false,
				action: "",
				fileList:[],
				dataForm: {
					recNo:"",
					probId:"",
					problemContent:"",
					problemRemark:"",
					registerTime:"",
					registerMan: $common.getItem("userName")
				},
				dataRule: {
					problemContent: [
						{ required: true, message: "跟踪内容未填写", trigger: "blur" }
					]
				},
			}
		},
		methods: {
			init(id,probId) {
				this.dataForm = {
					recNo:"",
					probId:"",
					problemContent:"",
					problemRemark:"",
					registerTime:"",
					registerMan: $common.getItem("userName")
				};
				this.dataForm.recNo = id?id:0;
				this.dataForm.probId = probId;
				if(id){
					this.getDetail();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getDetail(){
				this.$http({
				  url: this.$store.state.httpUrl + "/business/problemtrackrecord/info/"+this.dataForm.recNo,
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.dataForm = data.body;
				  }
				});
			},
			dataFormSubmit() {
			  this.$refs["dataForm"].validate(valid => {
				if (valid) {
				  let _url = "/business/problemtrackrecord/save";
				  if(this.dataForm.recNo && this.dataForm.recNo != 0){
					_url = "/business/problemtrackrecord/update";
				  }
				  this.$http({
					url: this.$store.state.httpUrl + _url,
					method: "post",
					data: this.$http.adornData(this.dataForm)
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
					  this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
						  this.visible = false;
						  this.$emit("refreshDataList");
						}
					  });
					} else {
					  this.$message.error(data.msg);
					}
				  });
				}
			  });
			}
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
