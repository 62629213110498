<template>
	<div>
		<el-dialog title="跟踪记录" :close-on-click-modal="false" :visible.sync="visible"
			width="950px">
			  <el-col :span="12">
				  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline" @click="addOrUpdateHandle()">添加</el-button>
			  </el-col>
				<el-col :span="12" style="text-align: right;">
					<el-pagination
							background
							@current-change="currentChangeHandle"
							layout="prev, pager, next"
							:current-page="pageIndex"
							:page-size="pageSize"
							:total="totalPage">
					</el-pagination>
				</el-col>
			<el-table
			  class="dataListTable"
			  :data="dataList"
			  header-align="center"
			  style="width: 100%; margin-top: 20px;"
			  header-cell-class-name="stepPointTHeader">
			  <el-table-column type="index" width="60" align="center">
			  </el-table-column>
			  <el-table-column prop="problemContent" label="跟踪内容" align="center">
			  </el-table-column>
			  <el-table-column prop="registerTime" label="登记时间"align="center" width="100" header-align="center">
			  </el-table-column>
			  <el-table-column prop="registerMan" label="登记人 " width="100" align="left" header-align="center">
			  </el-table-column>
			  <el-table-column prop="problemRemark" label="备注 " width="120" align="center">
			  </el-table-column>
			  <el-table-column
					  label="操作"
					  width="200"
					  align="center">
				  <template slot-scope="scope">
					  <el-button v-preventReClick type="danger" size="small" @click="addOrUpdateHandle(scope.row.recNo)">修改</el-button>
					  <el-button v-preventReClick type="success" size="small" @click="deleteHandle(scope.row.recNo)">删除</el-button>
				  </template>
			  </el-table-column>
			</el-table>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">返回</el-button>
			</el-row>
		</el-dialog>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
	</div>
</template>

<script>
	import AddOrUpdate from './problem-record-add-or-update'
	import $common from "@/utils/common.js"
	export default {
		name: "problem-record",
		data() {
			return {
				visible: false,
				inline: false,
				probId:"",
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 5,
				//总页数
				totalPage: 0,
				dataList:[],
				addOrUpdateVisible: false
			}
		},
		components: {
		    AddOrUpdate
		},
		methods: {
			init(id) {
				this.probId = id?id:0;
				if(id){
					this.getDataList();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getDataList(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/business/problemtrackrecord/list",
				  method: "get",
				  params: {
					  probId: this.probId,
					  limit: this.pageSize,
					  page: this.pageIndex
				  },
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
					}
				});
			},
			currentChangeHandle(val) {
				this.pageIndex = val;
				this.getDataList()
			},
			selGetDataList(){
				this.pageIndex = 1;
				this.getDataList();
			},
			// 新增检验项目
			addOrUpdateHandle (id){
			    this.addOrUpdateVisible = true;
			    this.$nextTick(() => {
			        this.$refs.addOrUpdate.init(id,this.probId)
			    })
			},
			deleteHandle (id) {
				this.$confirm(`您确定要删除选择的数据?`, '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
			    }).then(() => {
			        this.$http({
						url:  this.$store.state.httpUrl + "/business/ownercontractchange/delete/"+id,
			            method: 'post'
			        }).then(({data}) => {
			            if (data && data.resultCode === 200) {
			                this.$message({
			                    message: '操作成功',
			                    type: 'success',
			                    duration: 1500,
			                    onClose: () => {
			                        this.getDataList()
			                    }
			                })
			            } else {
			                this.$message.error(data.msg)
			            }
			        })
			    })
			},
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
